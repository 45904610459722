import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

import { PUBLIC_BASE_URL } from '$env/static/public';

Sentry.init({
	enabled: !PUBLIC_BASE_URL.includes('localhost'),
	dsn: 'https://7f4c58ba1f4978d2715d9158b1a0de28@sentry.pixelfield.dev/33',
	enableTracing: false,
	tracesSampleRate: 0,
  autoSessionTracking: false,
});

export const customHandleError: HandleClientError = async ({ error, event, status, message }) => {
	console.error('Error', error, 'status', status, 'event', event, 'message', message);
};

export const handleError = Sentry.handleErrorWithSentry(customHandleError);
